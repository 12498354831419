/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import HeaderMain from '../components/HeaderMain';
import Footer from '../components/Footer';
import HeroSingle from '../components/pages/HeroSingle';
import LineFull from '../images/linefull.jpg';
import Greenlogo from '../images/greenlogo.jpg';
import SEO from '../components/seo';
import '../styles/app.scss';

const openplay = ({ data }) => {
    const { wordpressPage: post } = data;
    const test_title = post.yoast_title;
    //const new_seo_title = test_title.replace('&#039;', "'");

    return (
        <>
            <HeaderMain />
            <HeroSingle pageTitle={post.title} />
            <section className="page-section smallestwdt">
                <div className="container">
                <h2 className="bluetxt openplaytxt">
                        All Open Play sessions must be booked.
                        </h2>
                    <div className="openplft">
                        <img
                            src={
                                post.acf.open_play_image_left.localFile
                                    .childImageSharp.fluid.src
                            }
                            alt="nonprofitimg"
                            loading="lazy"
                        />
                        <p><a href="https://werockthespectrumberwickvic.wrtsfranchise.com/wp-content/uploads/2020/06/WRTS-Geelong-Social-Story.pdf" class="wrtsbtn yellowbtn fullbtn mt-5" target="_blank">View Our Social Story</a></p>
						
                        <h5 className="bluetxt ttl9390">Prebooked Open Play</h5>
                        <iframe src="https://werockthespectrumberwickvic.wrtsfranchise.com/appointments-lowcapacity.php" className="healcode_widgets iframeshow" /> 
						 
						
                    </div>
                    <div
                        className="openprgt"
                        dangerouslySetInnerHTML={{
                            __html: post.acf.open_play_content_right,
                        }}
                    />
                    <div className="clearall"></div>
                </div>
            </section>
            <div className="container text_center mt-15">
                <img src={LineFull} alt="linefull" loading="lazy" />
            </div>
            <section className="page-section openphours">
                <div className="container smallestwdt">
                    <div className="twothirdcol">
                        <h2
                            className="bluetxt"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.open_play_schedule_title,
                            }}
                        />
                        {/* <p className='open-new-pp'>
                        <span><strong>Geelong's Open Play Schedule changes daily due to the nature of the services we offer and the other bookings that may be in place</strong></span>. Our opening hours are 9:30am-5:30pm, 7 days per week. We often have special events or programs that can run outside of these hours - it is best to give us a call on 0490 430 444 to find out more information! Bookings are essential.
                        </p> */}
                       <ul className="startxt starlistspacing">
                            <li class="greentxt">PLEASE CHECK THE BOOKINGS TAB OR OUR APP & SOCIALS FOR UPDATED HOURS!</li>
                        {/*    {post.acf.open_play_schedule_work_days.map(
                                (schedule, i) => (
                                    <li className="greentxt" key={i}>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    schedule.open_play_schedule_day,
                                            }}
                                        />
                                        : 
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    schedule.open_play_schedule_time,
                                            }}
                                        />
                                    </li>
                                )
                            )}
                            {post.acf.open_play_schedule_weekend.map(
                                (schedule, i) => (
                                    <li className="bluetxt" key={`ii_${i}`}>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    schedule.open_play_schedule_weekend_day,
                                            }}
                                        />
                                        :
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    schedule.open_play_schedule_weekend_time,
                                            }}
                                        />
                                    </li>
                                )
                            )}  */}
                            <p
                                className="pleasecallp"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        post.acf
                                            .open_play_please_call_ahead_text,
                                }}
                            />   
                            

                        </ul> 
                    </div>

                    <div className="onethirdcol">
                        <a
                            href="/register"
                            style={{ background: 'none', padding: 0 }}
                        >
                            <h6
                                className="greentxt"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        post.acf.open_play_registration_title,
                                }}
                            />
                        </a>
                        {/* <h2 className='bluetxt'>Low Capacity Open Play Pricing</h2>
                        <p className='open-new-pp'>
                        - $25 for the first child/participant<br/>
                        - $20 for siblings<br/>
                        **Bookings are essential for all children over the age of 6 months as we have toys and equipment to cater for everyone. Please make a booking per attending child.
                        </p> */}
                      {post.acf.open_play_register_widget_codes.map(
                            (scheduleCode, i) => (
                                <div
                                    className="healwrap"
                                    key={`key_${i}`}
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            scheduleCode.open_play_register_widget_code,
                                    }}
                                />
                            )
                        )} 
                    </div>
                </div>
            </section>

            <section
                className="whywelist page-section bg-primary text-white"
                id="openpbasic"
            >
                <div className="container flexwrap whywebluelist">
                    <div className="whylistlft flexbox whylisttxt todwn">
                        <img
                            className="whiteborderimg"
                            src={
                                post.acf.open_play_basic_info_image.localFile
                                    .childImageSharp.fluid.src
                            }
                            loading="lazy"
                            alt="open img"
                        />
                    </div>

                    <div className="whylistrgt flexbox toup">
                        <h2
                            className="yellowtxt"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.open_play_basic_info_title,
                            }}
                        />
                        <ul
                            className="startxt normalfontlist"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.open_play_basic_info_content,
                            }}
                        />
                    </div>
                </div>
            </section>

            <section className="page-section bg-secondary text-white centersec">
                <div className="container smallestwdt">
                <h2
                        className="yellowtxt"
                        dangerouslySetInnerHTML={{
                            __html: post.acf.open_play_membership_title,
                        }}
                    /> 
                 <p
                        className="biggertxt"
                        dangerouslySetInnerHTML={{
                            __html: post.acf.open_play_membership_description,
                        }}
                    /> 

                    {/* {post.acf.open_play_membership_list.map((membership, i) => (
                        <h5 key={`kt_${i}`}>
                            <span
                                className="yellowtxt"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        membership.open_play_membership_name,
                                }}
                            />
                            {' : '}
                            <span
                                dangerouslySetInnerHTML={{
                                    __html:
                                        membership.open_play_membership_price,
                                }}
                            />
                        </h5>
                    ))} */}
                </div>
            </section>

            <section className="page-section">
                <div className="container smallestwdt">
                    <div className="greenlft">
                        <img src={Greenlogo} alt="greenlogo" loading="lazy" />
                    </div>

                    <div className="greenrgt">
                        <h2
                            className="bluetxt"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.open_play_green_products_title,
                            }}
                        />
                        <p
                            className="biggertxt lastitem"
                            dangerouslySetInnerHTML={{
                                __html:
                                    post.acf.open_play_green_products_content,
                            }}
                        />
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default openplay;

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            id
            title
            slug
            acf {
                open_play_basic_info_content
                open_play_basic_info_image {
                    localFile {
                        childImageSharp {
                            fluid {
                                srcWebp
                                src
                            }
                        }
                    }
                }
                open_play_basic_info_title
                open_play_content_right
                open_play_content_second
                open_play_green_products_content
                open_play_green_products_title
                open_play_image_left {
                    localFile {
                        childImageSharp {
                            fluid {
                                srcWebp
                                src
                            }
                        }
                    }
                }
                open_play_membership_description
                open_play_membership_list {
                    open_play_membership_name
                    open_play_membership_price
                }
                open_play_membership_title
                open_play_please_call_ahead_text
                open_play_registration_title
                open_play_register_widget_codes {
                    open_play_register_widget_code
                }
                open_play_schedule_title
                open_play_schedule_work_days {
                    open_play_schedule_day
                    open_play_schedule_time
                }
                open_play_schedule_weekend {
                    open_play_schedule_weekend_day
                    open_play_schedule_weekend_time
                }
            }
        }
    }
`;
